import {store} from '../../store/store'; // Import your Redux store
import { logout } from '../../features/Auth/AuthSlice';

import {toast} from 'react-toastify'

export const checkAuth = () => {

  const state = store.getState();
  const user = state.auth.user; 
    if (user) {
      return true;
    }
    toast.info("Please Login to Continue")
    
    return false;
};




export const checkToken = () => {
  store.dispatch(logout());
    return false;
  };
  