export const BusinessCards=[
    {
        img:require("../assets/images/mob.jpg"),
        link:"/Mobility-Sector",
        title:"Mobility Industry",
        description:"Measure your Carbon Emissions as a Mobility Company.",
        status:true,
    },
    {
        img:require("../assets/images/power.jpg"),
        link:"/Power-Sector",
        title:"Power Industry",
        description:"Calculate your Carbon Impact as a Power Sector Business owner.",
        status:true,
    },
    {
        img:require("../assets/images/Finance.jpg"),
        link:"/Finance-Sector",
        title:"Finance Industry",
        description:"Measure your Carbon Emissions as a Finance Company owner.",
        status:true,
    },


]


export const PersonalCard =[
    {
        img:require("../assets/images/annualimpact.jpg"),
        link:"/individual",
        title:"Annual Impact",
        description:"Calculate your Individual Carbon Foot print for informed decisions.",
        status:true,

    },
    {
        img:require("../assets/images/ride.jpg"),
        link:"/Personal-Ride",
        title:"Personal Ride",
        description:"Calculate your Individual Carbon Foot print for informed decisions.",
        status:true,

    },

]


export const InstructionData =[ {
    title:"WHO WE ARE",
    articelLink:"/who-we-are",
    helpLink:"/contact"
},
{
    title:"WHAT WE NEED FROM YOU",
    articelLink:"/what-we-need",
    helpLink:"/contact"
},
{
    title:"WHAT WILL YOU GET",
    articelLink:"/what-will-you-get",
    helpLink:"/contact"
},
]


