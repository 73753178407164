import React from 'react'
import "./Hero.css"
import Header from '../../Header/Header'

const Hero = () => {
  return (
    <div className='H_Hero'>  <Header/>
<div className=' site_padding' data-aos="fade-up" >
        <div className='H_Hero_top'>

        <h1>FORI </h1><h1 className='H_Hero_h1'>INSIGHTS</h1>
        </div>
        <h2>LET'S JOURNEY TO CARBON</h2>
        <div className='H_Hero_C1'>
            <h5>The inaugural comprehensive solution for businesses to assess their environmental impact</h5>
            <h1>NEUTRALITY</h1>
        </div>
    </div>
    </div>
      )
}

export default Hero