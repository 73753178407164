import React from 'react'
import './Instructions.css'
import { Link } from 'react-router-dom'
import {InstructionData} from '../../../assets/data'
const Instructions = () => {
  return (
    <div className='Instructions site_padding'>
        <p>/ GET INFORMED</p>
        <h2>READ  <br />ALL BLOGS</h2>

        <div className='Instructions_bottom'>

            {
            InstructionData.map((item, index)=>{
                return(
                        <div key={index}>
                        <h3>{item.title}</h3>
                            <div className='instructionBottom_links'>
                                <Link to={item.articelLink}>READ ARTICLE</Link>
                                <Link to={item.helpLink}>GET HELP</Link>

                            </div>
                        </div>

                )            
            })
            }
        </div>
        <div className='instruction_contact flex_column'>
        <h5>WE HELP BUSINESS TO INNOVATE AND REMAIN HIGHLY RELEVANT TO THEIR CUSTOMERS BY DEVELOPING EDGE DIGITAL SOLUTIONS</h5>
            <Link className='btn' to='/contact' >CONTACT US</Link>

        </div>
    </div>
  )
}

export default Instructions