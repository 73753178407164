import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {toast} from 'react-toastify'

import {encryptAndStore, decryptAndRetrieve}  from "../../utils/Encryptions/Encryption"
// get user from local sy=torage
const user = decryptAndRetrieve('user')
const initialState = {
    user:user ? user: null,
    isError:false,
    isSuccess:false,
    isLoading:false,
    message:'',
}

export const verifyUser = createAsyncThunk('auth/verifyUser',
async(formData, thunkAPI)=>{
    try{
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/auth/verify`
        const response = await axios.post(API_URL, formData)
        if(response.data.success===true){
            encryptAndStore(formData,"verification_data" )  
        }
        return response.data
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
}
)




// register user
export const register = createAsyncThunk('auth/register',
async(token, thunkAPI)=>{
    try{
        
        // const API_URL = 'https://analytics-backend.fori.co/api/users/register'
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/auth/register`
        //register user
        const response = await axios.post(API_URL, {token})

        if(response.data.success===true) {
            localStorage.removeItem("verification_data")
            encryptAndStore(response.data,'user' )  
        }
        return response.data
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
}
)

export const login = createAsyncThunk('auth/login',
async(formData, thunkAPI)=>{
    try{

        console.log(formData)
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/auth/login`

        //Login user
        const response = await axios.post(API_URL, formData)

        if(response.data.success===true){
            encryptAndStore(response.data,'user' )  
        }
        return response.data
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
}
)

export const forgotpassword = createAsyncThunk('auth/forgotpassword',
async(formData, thunkAPI)=>{
    try{

        const API_URL = `${process.env.REACT_APP_SERVER_URL}/auth/forgotPassword`
        const response = await axios.post(API_URL, formData)

        return response.data
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
}
)


// reset password
export const ResetPasswordFunction = createAsyncThunk('auth/ResetPassword/sendEmail', async(ResetData, thunkAPI)=>{

    try{
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/auth/resetPassword`
        const response = await axios.put(API_URL, ResetData)
        return response.data
 
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})


// logout
export const logout  = createAsyncThunk('auth/logout', async(_,thunkAPI)=>{
    //logout user
    try{
       localStorage.removeItem('user')
       thunkAPI.dispatch(resetAll());
    }catch(error){
        return thunkAPI.rejectWithValue('Logout Unsuccessful')
    }
})


export const authSlice = createSlice({
    name:'auth',
    initialState,
    reducers:{
        reset:(state)=>{
            state.isLoading = false
            state.isSuccess = false
            state.isError = false
            state.message = ''
        },
        resetAll:(state)=>{
            state.isLoading = false
            state.isSuccess = false
            state.isError = false
            state.message = ''
            state.user = null
        },


    },
    extraReducers:(builder) => {
        builder
        // ...............verifyUser..................verifyUser.................verifyUser...................verifyUser.........verifyUser
        .addCase(verifyUser.pending, (state)=>{
            state.isLoading = true;
        })
        .addCase(verifyUser.fulfilled, (state, action)=>{
            console.log(action.payload,'verify fulfilled payload')

            state.isLoading = false
            state.isSuccess = true
            state.message = action.payload.message
            toast.info(action.payload.message)
        })
        .addCase(verifyUser.rejected, (state, action)=>{
            console.log(action.payload,'payload')
            state.isLoading = false
            state.isError = true
            state.message = action.payload
            toast.error(action.payload)

        })
        // ...............register.......................register.......................register....................register...........register
        .addCase(register.pending, (state)=>{
            state.isLoading = true;
        })
        .addCase(register.fulfilled, (state, action)=>{
            if(action.payload.success===true){
                state.user = action.payload
            }
            state.isLoading = false
            state.isSuccess = true
            state.message = action.payload.message
            toast.info(action.payload.message)
       
        })
        .addCase(register.rejected, (state, action)=>{
            console.log(action.payload,'rejected payload')

            state.isLoading = false
            state.isError = true
            state.message = action.payload
            state.user = null
            toast.error(action.payload)


        })
        // ........login................login..................login..................login.........login...........login
        .addCase(login.pending, (state)=>{
            state.isLoading = true;
        })
        .addCase(login.fulfilled, (state, action)=>{
            if(action.payload.success===true){
                state.user = action.payload

            }
            state.isLoading = false
            state.isSuccess = true
            toast.info(action.payload.message)

       
        })
        .addCase(login.rejected, (state, action)=>{
            state.isLoading = false
            state.isError = true
            state.message = action.payload
            state.user = null
            toast.error(action.payload)

        })
        
        // forgotpassword...........forgotpassword................forgotpassword.....forgotpassword.........forgotpassword
        .addCase(forgotpassword.pending, (state)=>{
            state.isLoading = true;
        })
        .addCase(forgotpassword.fulfilled, (state, action)=>{
            state.isLoading = false
            state.isSuccess = true
            toast.info(action.payload.message)
        })
        .addCase(forgotpassword.rejected, (state, action)=>{
            state.isLoading = false
            state.isError = true
            state.message = action.payload
            toast.info(action.payload)

        })
        // ..........resetPassword...............resetPassword..................resetPassword.............resetPassword...........resetPassword........
        .addCase(ResetPasswordFunction.pending, (state, action)=>{
            state.isLoading = true
        })
        .addCase(ResetPasswordFunction.fulfilled, (state, action)=>{
            state.message = action.payload.message
            toast.info(action.payload.message)
            state.isLoading= false
            state.isSuccess = true
            console.log("accepted")
        })
        .addCase(ResetPasswordFunction.rejected, (state, action)=>{
            state.isError = true
            state.isLoading = false
            toast.error(action.payload)
            console.log("rejected")

        })
        // .....logout..............logout...............logout....................logout..................logout...........logout
        .addCase(logout.fulfilled, (state)=>{
            state.isLoading = false
            state.isError = false
            state.isSuccess = false
            state.message = ''
            state.user = null
        })
    }
})

export const {reset,resetAll} = authSlice.actions
export default authSlice.reducer