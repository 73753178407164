import "./IndustryCard.css";
import {BusinessCards} from "../../../assets/data"
import {PersonalCard} from "../../../assets/data"
import { Link } from "react-router-dom";
import {FaExpandArrowsAlt} from 'react-icons/fa'
const IndustryCard = () => {
  return (
    <div className="Industry_card site_padding"  >
      <div id="Business_Collaboration"className="Industry_car_row">
        
      <div className="industryCard_title">
    <h4>We help businesses to Calculate their Environmental Impact</h4>
    <p>Not only measure but also track their record and share their journey with others to become an inspiration for the world.</p>
      </div>
    <div className="industry_div">
    {BusinessCards.map((industry, index)=>{
        return(
          <div className="Card_parent" style={{backgroundColor:index%2===0?"#f6f6f6":"#000", color:index%2===0?"#000":"#fff"}} key={index}>
            <div className="card_child_left">
            <Link to={industry.link} className={index%2===0?"secondary_btn":"btn"}>Explore</Link>
            <div>
            <small>Let's Calculate Your</small>
              <h4>{industry.title}</h4>
              <small>Impact</small>
              <p>{industry.description}</p>


            </div>
     
            </div>

            <div className="card_child_right">
            <img src={industry.img} alt={industry.h+"image"}/>
            {industry.status===true ?<Link to={industry.link} ><FaExpandArrowsAlt/></Link> : <p>Coming Soon</p>}

            </div>
          </div>
          
        )
    })}
    </div>
    </div>
    <div className='border_horizontal'></div>


    <div  className="Industry_car_row">

    <div className="industryCard_title">
    <h4>Personal Level Calculations</h4>
    <p>Not just assessing one's progress but also monitoring their achievements.</p>
      </div>
    <div className="industry_div">
    {PersonalCard.map((industry, index)=>{
        return(
          <div className="Card_parent" style={{backgroundColor:index%2===0?"#f6f6f6":"#000", color:index%2===0?"#000":"#fff"}} key={index}>
            <div className="card_child_left">
            <Link to={industry.link} className={index%2===0?"secondary_btn":"btn"}>Explore</Link>
            <div>
            <small>Let's Calculate Your</small>
              <h4>{industry.title}</h4>
            <small>Impact</small>

              <p>{industry.description}</p>
            </div>
     
            </div>

            <div className="card_child_right">
            <img src={industry.img} alt={industry.h+"image"}/>
            {industry.status===true ?<Link to={industry.link} ><FaExpandArrowsAlt/></Link> : <p>Coming Soon</p>}
            

            </div>
          </div>
          
        )
    })}
    </div>
    </div>


    </div>
  )
}


export default IndustryCard