import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {toast} from 'react-toastify'
import {checkToken} from '../../utils/CheckAuth/CheckAuth'

const initialState = {
    // AwardData:{treesGrown:10, Annualco2Saved: 0,currentCO2:0},
    AwardData:null,
    isError:false,
    isSuccess:false,
    isLoading:false,
    message:'',
}



export const GetAwardData = createAsyncThunk('Awards/AwardData',
async(_, thunkAPI)=>{
    try{

        const token =await thunkAPI.getState().auth.user.token
        const config = {
            headers:{
                Authorization:`Bearer ${token}`
            }
        }
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/progress/GetindividualAwards`
        const response = await axios.get(API_URL, config)
        
        return response.data
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        if(message==="Session Expired, please login again to continue"){
            checkToken()
        }
        return thunkAPI.rejectWithValue(message)
    }
}
)








export const AwardSlice = createSlice({
    name:'AwardSlice',
    initialState,
    reducers:{
        reset:(state)=>{
            state.AwardData = null
            state.isLoading = false
            state.isSuccess = false
            state.isError = false
            state.message = ''
        },

    },
    extraReducers:(builder) => {
        builder
        // ...............GetAwardData..................GetAwardData.................GetAwardData...................SingleRideImpact.........SingleRideImpact
        .addCase(GetAwardData.pending, (state)=>{
            state.isLoading = true;
        })
        .addCase(GetAwardData.fulfilled, (state, action)=>{
            state.AwardData =  action.payload.AwardData
            state.isLoading = false
            state.isSuccess = true
            console.log(action.payload)
            state.message = action.payload.message
        })
        .addCase(GetAwardData.rejected, (state, action)=>{
            state.AwardData =  null
            state.isLoading = false
            state.isError = true
            state.message = action.payload
            toast.error(action.payload)
        })


      



            
    }


    
})

export const {reset} = AwardSlice.actions
export default AwardSlice.reducer