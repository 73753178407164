import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
const themecolor=JSON.parse(localStorage.getItem("themeColor"))
const thememode=JSON.parse(localStorage.getItem("themeMode"))
const initialState = {
  currentMode:thememode?thememode: 'Light',
  currentColor:  themecolor ? themecolor : { Bg: "#fffff", primary: "black", secondary: "#fea022" }
};

// Define your async thunks
export const fetchThemeData = createAsyncThunk('theme/fetchThemeData', async (_, thunkAPI) => {
  try {
    const themeData = { mode: 'Light', color: {Bg:"#fffff",primary:"black",secondary:"#fea022"} };
    return themeData;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setMode: (state, action) => {
      state.currentMode = action.payload;
    },
    setTheme: (state, action) => {
      document.documentElement.style.setProperty('--db_Bg', action.payload.Bg);
      document.documentElement.style.setProperty('--db_primary', action.payload.primary);
      document.documentElement.style.setProperty('--db_secondary', action.payload.secondary);
      localStorage.setItem("themeColor",JSON.stringify(action.payload))
      state.currentColor = action.payload;
    },
    setSelectedTheme: (state, action) => {
      document.documentElement.style.setProperty('--db_Bg', state.currentColor.Bg);
      document.documentElement.style.setProperty('--db_primary',state.currentColor.primary);
      document.documentElement.style.setProperty('--db_secondary', state.currentColor.secondary);
    },
    resetTheme: (state) => {
      state.currentMode = 'Light';
      state.currentColor = {Bg:"#fffff",primary:"green",secondary:"red"} ;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchThemeData.pending, (state) => {
      })
      .addCase(fetchThemeData.fulfilled, (state, action) => {
        state.currentMode = action.payload.mode;
        state.currentColor = action.payload.color;
      })
      .addCase(fetchThemeData.rejected, (state, action) => {
      });
  },
});

export const { setMode, setTheme, resetTheme ,setSelectedTheme} = themeSlice.actions;
export default themeSlice.reducer;



