import React from 'react'
import './Statement.css'
const Statement = () => {
  return (
    <>
    <div className='Statement site_padding'>
        <h2>WE HELP BUSINESS TO INNOVATE AND REMAIN HIGHLY RELEVANT TO THEIR CUSTOMERS BY DEVELOPING EDGE DIGITAL SOLUTIONS</h2>
        <div className='statement_bottom'>
            <div className="statement_bottom_card">
                <h4>THE CHALLENGE</h4>
                <p>Driving a vehicle is a convenient and comfortable way of transportation, but it also has a significant impact on the environment and human health. Vehicles emit carbon dioxide (CO<sub>2</sub>) and other greenhouse gases that contribute to global warming and climate change. According to the World Health Organization, transport-related air pollution causes about 3.7 million premature deaths every year. How can we reduce the negative effects of driving a vehicle? These are some of the challenges that FORI INSIGHTS aims to address. </p>
            </div>
            <div className="statement_bottom_card">
                <h4>THE APPROACH</h4>
                <p>FORI INSIGHTS uses a simple and user-friendly approach to help you measure and reduce your vehicle’s carbon footprint. Fori Insights calculates the amount of CO<sub>2</sub> your vehicle emits per year, per month, or per trip. FORI INSIGHTS is more than just a website. It is a community of people who care about the environment and want to make a positive impact. You can share your results and achievements with your friends and family on social media. Together, we can make a difference for the planet and future generations.</p>
            </div>
        </div>

     
    </div>
       <div className='bg_img site_padding'>
       <h2>LET'S TAKE FIRST <br />STEP TOGETHER</h2>
       <p>-------------------</p>
       <div>
       <h5>TOWARDS </h5>
       <h4 style={{color:"#e2f8d0"}}>CARBON NEUTRALITY</h4>

       </div>
       
        </div>
   </>
  )
}

export default Statement