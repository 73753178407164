import React from 'react';
import { useNavigate } from 'react-router-dom';
import { MdKeyboardBackspace } from "react-icons/md";

const GoBackButton = () => {
  const navigate = useNavigate();
  const pathname = window.location.pathname

  const goBack = () => {
    navigate(-1);
  };

  const goHome = () => {
    navigate("/");
  };
  return (
          <MdKeyboardBackspace className={pathname==="/individual"?"back_button_visible":pathname==="/"?"back_button_hidden":"back_button"} onClick={pathname==="/login" ||pathname=== '/individual' ?goHome:goBack} />
  );
};

export default GoBackButton;