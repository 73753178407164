import React ,{useState}from 'react'
import './MobileNav.css' ;
import Drawer from '../Drawer/Drawer'

import {Link} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {logout} from '../../features/Auth/AuthSlice'


const MobileNav = () => {
const {user} =  useSelector((store)=>store.auth)
const dispatch = useDispatch()

    const [show, setShow] = useState(false)
    const [firstShow, setFirstShow] = useState(true)


    const showFunction =()=>{
      setFirstShow(false)
      setShow(!show)
    }


    const logoutFunction =()=>{
      setFirstShow(false)
      setShow(!show)
      dispatch(logout())
     
    }

  return (
    <div className='Mob_Drawer_parent'>
         <Drawer />

    <div className="Mob_Navbar">
        <div className={show?"Mob_Navbar_right":(firstShow===false && show===false)?'hidden_animation':"hidee"}>
            <Link to="/" onClick={showFunction}>HOME</Link>
            <Link to="/my-account" onClick={showFunction}>ACCOUNT</Link>
            <Link  to='/subscribe' onClick={showFunction}>SUBSCRIBE</Link>
            <Link  to="/donate" onClick={showFunction}>DONATE</Link>
            <Link to="/contact" onClick={showFunction}>CONTACT</Link>
           {user ?
             <button onClick={logoutFunction}>LOGOUT</button>:
            <>
            <Link to="/login" onClick={showFunction}>LOGIN</Link>
            </>
           
            }

        </div>
        
        <div className={show?'cross burger':'burger'} onClick={showFunction}>
          <div></div>
          <div></div>
        </div>
       

       
    </div>
    </div>
  )
}

export default MobileNav

