import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {toast} from 'react-toastify'
import {checkToken} from '../../utils/CheckAuth/CheckAuth'


const initialState = {
    PowerImpactData:null,
    powerType:"",
    isError:false,
    isSuccess:false,
    isLoading:false,
    message:'',
}




export const PowerImpact = createAsyncThunk('Power/PowerImpact',
async(formData, thunkAPI)=>{
    try{
        const { kwh ,sourceType } =  formData

        const token =await thunkAPI.getState().auth.user.token
        const config = {
            headers:{
                Authorization:`Bearer ${token}`
            }
        }

        const API_URL = `${process.env.REACT_APP_SERVER_URL}/insights/PowerImpact`
        const response = await axios.post(API_URL, { kwh ,sourceType },config)

        return response.data
    }catch(error){

        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        if(message==="Session Expired, please login again to continue"){
            checkToken()
        }
        return thunkAPI.rejectWithValue(message)
    }
}
)




export const PowerImpactSlice = createSlice({
    name:'PowerImpactSlice',
    initialState,
    reducers:{
        reset:(state)=>{
            state.PowerImpactData = null
            state.isLoading = false
            state.isSuccess = false
            state.isError = false
            state.message = ''
        },
        resetStatusKeys:(state)=>{
            console.log("reset chla")
            state.isLoading = false
            state.isSuccess = false
            state.isError = false
            state.message = ''

        },

    },
    extraReducers:(builder) => {
        builder
        // ...............PowerImpact..................PowerImpact.................PowerImpact...................PowerImpact.........PowerImpact
        .addCase(PowerImpact.pending, (state)=>{
            state.isLoading = true;
            state.isError = false;
            state.isSuccess = false;
        })
        .addCase(PowerImpact.fulfilled, (state, action)=>{
            state.PowerImpactData =  action.payload.PowerImpactData
            state.powerType =  action.payload.powerType
            state.isLoading = false
            state.isSuccess = true
            state.message = action.payload.message
            toast.info(action.payload.message)
            console.log(action.payload)
        })
        .addCase(PowerImpact.rejected, (state, action)=>{
            state.PowerImpactData =  null
            state.powerType =  ""
            state.isLoading = false
            state.isError = true
            state.message = action.payload
            toast.error(action.payload)
        })         
    }
})

export const {reset,resetStatusKeys} = PowerImpactSlice.actions
export default PowerImpactSlice.reducer