import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {toast} from 'react-toastify'
import {checkToken} from '../../utils/CheckAuth/CheckAuth'


const initialState = {
    FinanceImpactData:null,
    isError:false,
    isSuccess:false,
    isLoading:false,
    message:'',
}




export const FinanceImpact = createAsyncThunk('Finance/FinanceImpact',
async(formData, thunkAPI)=>{
    try{
        console.log(formData,'formData at slice');
        const token =await thunkAPI.getState().auth.user.token
        console.log(token,'token');
        const config = {
            headers:{
                Authorization:`Bearer ${token}`
            }
        }

        const API_URL = `${process.env.REACT_APP_SERVER_URL}/insights/FinanceImpact`
        console.log(config,'config');
        const response = await axios.post(API_URL, formData, config)
        return response.data

    }catch(error){

        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        if(message==="Session Expired, please login again to continue"){
            checkToken()
        }
        return thunkAPI.rejectWithValue(message)
    }
}
)




export const FinanceImpactSlice = createSlice({
    name:'FinanceImpactSlice',
    initialState,
    reducers:{
        reset:(state)=>{
            state.FinanceImpactData = null
            state.isLoading = false
            state.isSuccess = false
            state.isError = false
            state.message = ''
        },
    },
    extraReducers:(builder) => {
        builder
        // ...............FinanceImpact..................FinanceImpact.................FinanceImpact...................FinanceImpact.........FinanceImpact
        .addCase(FinanceImpact.pending, (state)=>{
            state.isLoading = true;
        })
        .addCase(FinanceImpact.fulfilled, (state, action)=>{
            state.FinanceImpactData =  action.payload.FinanceImpactData
            state.isLoading = false
            state.isSuccess = true
            console.log(action.payload)
            state.message = action.payload.message
        })
        .addCase(FinanceImpact.rejected, (state, action)=>{
            state.FinanceImpactData =  null
            state.isLoading = false
            state.isError = true
            state.message = action.payload
            toast.error(action.payload)
        })         
    }
})

export const {reset,resetStatusKeys} = FinanceImpactSlice.actions
export default FinanceImpactSlice.reducer