import CryptoJS from 'crypto-js';

// Encryption function
export const encryptAndStore =(data, key)=> {
  const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), "yourEncryptionKey");
  localStorage.setItem(key, encryptedData);
  return encryptedData.toString();
}

// Decryption function
export const decryptAndRetrieve = (key) => {
  const encryptedData = localStorage.getItem(key)
  if(encryptedData){
  // const decryptedUser = JSON.parse(decrypt(encryptedUserData, encryptionKey));
  const decryptedBytes = CryptoJS.AES.decrypt(encryptedData, "yourEncryptionKey");
  const decryptedData = decryptedBytes.toString(CryptoJS.enc.Utf8);
  return JSON.parse(decryptedData);
  }
  return null;

}



// encryptAndStore({a:"1", b:"2"}, "test")
// console.log(decryptAndRetrieve("test"))
