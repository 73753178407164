import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpen:false,
  data:null,
};


const themeSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal: (state, action) => {
      state.isOpen = true
      state.data = action.payload
    },
    closeModal: (state) => {
        state.isOpen = false
      state.data = null

    },


 

  },

});

export const { openModal,closeModal} = themeSlice.actions;
export default themeSlice.reducer;



