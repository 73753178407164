import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/Auth/AuthSlice'
import MobilityImpactReducer from '../features/Mobility/MobilityImpactSlice'
import PowerImpactReducer from '../features/Power/PowerImpactSlice'
import FinanceImpactReducer from '../features/Finance/FinanceImpactSlice'
import IndividualImpactReducer from '../features/IndiviualImpact/IndiviualImpact'
import paymentReducer from '../features/Payment/paymentSlice';
import contactReducer from '../features/Contact/ContactSlice'
import themeReducer from '../features/theme/themeSlice';
import AwardReducer from '../features/Awards/AwardsSlice';
import ModalReducer from '../features/Modal/Modal';
export const store = configureStore({
  reducer: {
    auth:authReducer,
    mobilityImpact:MobilityImpactReducer,
    powerImpact:PowerImpactReducer,
    financeImpact:FinanceImpactReducer,
    payment:paymentReducer,
    theme:themeReducer,
    contact:contactReducer,
    IndividualImpact:IndividualImpactReducer,
    awards:AwardReducer,
    modal:ModalReducer
  },
});

//test comment