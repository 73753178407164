import React from 'react'
import './TextLoop.css'
const TextLoop = () => {
  return (
    <section className="container">
    <div className="list">
      <div className="item">
        <span className="item-txt">CARBON</span>
        <span className="item-d">
          <p className="item-dot dot-t"></p>
        </span>
        <span className="item-txt">IMPACT</span>
        <span className="item-d">
          <p className="item-dot dot-b"></p>
        </span>
        <span className="item-txt">MOBILITY</span>
        <span className="item-d">
          <p className="item-dot dot-g"></p>
        </span>
        <span className="item-txt">POWER</span>
        <span className="item-d">
          <p className="item-dot dot-y"></p>
        </span>
        <span className="item-txt">& MORE</span>
        <span className="item-d">
          <p className="item-dot dot-r"></p>
        </span>
       </div>
    </div>
      <div className="list">
      <div className="item">
        <span className="item-txt">CARBON</span>
        <span className="item-d">
          <p className="item-dot dot-t"></p>
        </span>
        <span className="item-txt">IMPACT</span>
        <span className="item-d">
          <p className="item-dot dot-b"></p>
        </span>
        <span className="item-txt">MOBILITY</span>
        <span className="item-d">
          <p className="item-dot dot-g"></p>
        </span>
        <span className="item-txt">POWER</span>
        <span className="item-d">
          <p className="item-dot dot-y"></p>
        </span>
        <span className="item-txt">& MORE</span>
        <span className="item-d">
          <p className="item-dot dot-r"></p>
        </span>
       </div>
    </div>
  </section>
  )
}

export default TextLoop