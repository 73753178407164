import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import { store } from './store/store';
import { BrowserRouter as Router} from 'react-router-dom'


const renderApp = () => {
    const root = ReactDOM.createRoot(document.getElementById('root'));
    
    root.render(
      <Provider store={store}>
        <div className="App">
          <Router>
            <App/>
          </Router>
      </div>
    </Provider>
    );
  };
  renderApp()
