import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {toast} from 'react-toastify'
import {checkToken} from '../../utils/CheckAuth/CheckAuth'

const initialState = {
    IndividualImpactData:null,
    isError:false,
    isSuccess:false,
    isLoading:false,
    message:'',
}



export const GetIndividualImpact = createAsyncThunk('IndividualImpact/IndividualImpactData',
async(formData, thunkAPI)=>{
    try{

        console.log(formData,"form Data")
        const token =await thunkAPI.getState().auth.user.token
        const config = {
            headers:{
                Authorization:`Bearer ${token}`
            }
        }
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/IndividualImpact`
        const response = await axios.post(API_URL,formData, config)
        
        return response.data
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        if(message==="Session Expired, please login again to continue"){
            checkToken()
        }
        return thunkAPI.rejectWithValue(message)
    }
}
)








export const IndividualImpactSlice = createSlice({
    name:'IndividualImpact',
    initialState,
    reducers:{
        reset:(state)=>{
            state.IndividualImpactData = null
            state.isLoading = false
            state.isSuccess = false
            state.isError = false
            state.message = ''
        },

    },
    extraReducers:(builder) => {
        builder
        // ...............GetIndividualImpact..................GetIndividualImpact.................GetIndividualImpact...................SingleRideImpact.........SingleRideImpact
        .addCase(GetIndividualImpact.pending, (state)=>{
            state.isLoading = true;
        })
        .addCase(GetIndividualImpact.fulfilled, (state, action)=>{
            state.IndividualImpactData =  action.payload.IndividualImpactData
            state.isLoading = false
            state.isSuccess = true
            console.log(action.payload)
            state.message = action.payload.message
        })
        .addCase(GetIndividualImpact.rejected, (state, action)=>{
            state.IndividualImpactData =  null
            state.isLoading = false
            state.isError = true
            state.message = action.payload
            toast.error(action.payload)
        })


      



            
    }


    
})

export const {reset} = IndividualImpactSlice.actions
export default IndividualImpactSlice.reducer