import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {toast} from 'react-toastify'


const initialState = {
    data:null,
    isError:false,
    isSuccess:false,
    isLoading:false,
    message:'',
}

export const SendMessage = createAsyncThunk('contact/SendMessage',
async(formData, thunkAPI)=>{
    console.log(formData,"ja")
    try{
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/contact/sendMessgae`
        const response = await axios.post(API_URL, formData)
        return response.data
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
}
)




export const contactSlice = createSlice({
    name:'contact',
    initialState,
    reducers:{
        reset:(state)=>{
            state.data= null
            state.isLoading = false
            state.isSuccess = false
            state.isError = false
            state.message = ''
        },


    },
    extraReducers:(builder) => {
        builder
        // ...............SendMessage..................SendMessage.................SendMessage...................SendMessage.........SendMessage
        .addCase(SendMessage.pending, (state)=>{
            state.isLoading = true;
        })
        .addCase(SendMessage.fulfilled, (state, action)=>{
            state.isLoading = false
            state.isSuccess = true
            state.message = action.payload.message
            toast.info(action.payload.message)
        })
        .addCase(SendMessage.rejected, (state, action)=>{
            state.isLoading = false
            state.isError = true
            state.message = action.payload
            toast.error(action.payload)

        })
    }
})

export const {reset} = contactSlice.actions
export default contactSlice.reducer