import React from 'react'
import './Home.css'
import Hero from '../../components/home/Hero/Hero'
import IndustryCard from '../../components/home/IndustryCard/IndustryCard'
import Statement from '../../components/home/Statement/Statement'
import Instructions from '../../components/home/Instructions/Instructions'
import TextLoop from '../../components/home/TextLoop/TextLoop'
const Home = () => {
  return (
    <div>
        <Hero/>
        <IndustryCard/>
        <Statement/>
        <Instructions/>
        <TextLoop/>

    </div>
  )
}

export default Home