import React from 'react'
import './Footer.css'
import {HiOutlineShare} from 'react-icons/hi'
import {BsArrowUpRight} from 'react-icons/bs'
import {AiFillInstagram} from 'react-icons/ai'
import {AiFillLinkedin} from 'react-icons/ai'
import {BiWorld} from 'react-icons/bi'

function topFunction() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }
  const currentYear = new Date().getFullYear(); // Get the current year dynamically

const Footer = () => {
  return (
    <div className='Footer site_padding'>
        <h5>WE WOULD LOVE TO HEAR FROM YOU. <br />LET'S WORK TOGETHER</h5>
        <div>

        <div className='Footer_bottom'>
            <h6 onClick={topFunction}>BACK TOP <BsArrowUpRight /></h6>
            <h6>FORI &#169; {currentYear}</h6>
            <h6 className='followus'>FOLLOW US <HiOutlineShare/></h6>
        </div>

        <div className='followus_links'>
            <a href='https://instagram.com/fori_inc?igshid=NzZhOTFlYzFmZQ=='><AiFillInstagram/></a>
            <a href='https://www.linkedin.com/company/foriinc/'><AiFillLinkedin/></a>
            <a href='https://fori.co'><BiWorld/></a>
        </div>

        </div>

    </div>
  )
}

export default Footer